.form-control {
  border-bottom: 3px solid $primary;
}

.form-check-input {
  + .form-check-label {
    margin-left: 20px;
  }
}

.identification-upload {
  .form-control {
    background-color: white;
  }

  .form-select {
    padding: 0.4em 90px 0.4em 30px;
    box-shadow: none;
    border-bottom: 3px solid $primary;
  }
}

.form-check-inline {
  margin-right: 3rem;
}

.dropzone {
  padding: 24px;
  background-color: #fff;

  .dz-message {
    padding: 40px 70px 50px;
    border: 1px solid $secondary;
    text-align: center;

    // only show where can be clicked
    cursor: pointer;

    &:hover {
      background-color: $light;
    }
    &.dz-started & {
      display: none;
    }
  }



  .dz-details {
    display: flex;
    gap: 20px;
    margin: 0 0 20px;
    background: $white;
    padding: 5px 0;
    align-items: center;


    .dz-size {
      margin-left: auto;
    }

    .dz-remove {
      border: 0;
      padding: 0 10px;
      color: $danger;
    }

    &__total {
      padding-top: 10px;
      margin-bottom: 10px;
    }
  }
}
