@use "sass:color";

// Colors
$primary: #d3df00;
$secondary: #767d00;
$green: #95da8a;
$yellow: #fdeb5d;
$red: #dc3545;

$light: #f5f5f1;
$dark: #282c32;
$black: #000;
$white: #fff;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "dark": $dark,
  "white": $white,
  "warning": #ffc107,
  "success": #198754,
  "danger": $red,
  "dark-with-image": $dark,
);

$min-contrast-ratio: 2;

// Text colors
$text-muted: $light;
$body-color: $dark;

// border classes
$border-color: $primary;

// <hr>
$hr-color: $primary;
$hr-height: 2px;
$hr-opacity: 1;

// Navbar
$navbar-nav-link-padding-x: 0.5rem;
$navbar-light-color: $body-color;
$navbar-light-active-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-brand-margin-end: 2em;

// Grid
// Adjust according to Sketch grid settings
$grid-gutter-width: 42px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1344px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
  xxl: 1400px,
);

$spacers: (
  0: 0,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  30: 30px,
  40: 40px,
  60: 60px,
  80: 80px,
  100: 100px,
  120: 120px,
);

$aspect-ratios: (
  "1x1": 100%,
  "16x9": calc(100% / 16 * 9),
  "3x2": calc(100% / 3 * 2),
);

// General Style Settings
$enable-smooth-scroll: true;
$enable-negative-margins: true;
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: false;
$enable-responsive-font-sizes: true;
$enable-important-utilities: false; // disables !important for all bootstrap classes, set to true if this causes issues.

// Fonts
$font-family-base: "Nexa Text", "Segoe UI", Tahoma, sans-serif;
$headings-font-family: "Nexa", serif;

// Typography
// Headings

$headings-margin-bottom: 20px;
$headings-line-height: 1.3;
$headings-color: var(--foreground, #{$secondary});

$h1-font-size: 42px;
$h2-font-size: 42px;
$h3-font-size: 28px;
$h4-font-size: 24px;
$h5-font-size: 22px;
$h6-font-size: 16px;

$font-size-base: 1rem;
$line-height-base: 1.75;
$small-font-size: 0.875rem;

$display-font-sizes: (
  1: 60px,
);
$display-line-height: 1;
$display-font-weight: 900;

// Links
$link-color: var(--foreground, #{$primary});
$link-hover-color: var(
  --foreground-hover,
  #{color.adjust($primary, $lightness: -10%)}
);
$link-decoration: underline;

// Forms
$input-bg: var(--input-bg, #{$light});
$input-border-width: 0;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-placeholder-color: $dark;
$input-color: $dark;
$input-padding-x: 1em;
$input-padding-y: 0.4em;
$input-font-size: 1.125em;

$form-check-input-border: 2px solid $dark;
$form-check-input-checked-bg-color: $secondary;

$form-select-bg: #fff;
$form-select-padding-x: 30px;
$form-select-padding-y: 20px;
$form-select-font-weight: bold;

// Buttons
$btn-font-size: 1rem;
$btn-font-weight: 700;
$btn-link-color: $body-color;
$btn-padding-y: 0.875em;
$btn-padding-x: 3.125em;
$btn-border-width: 3px;

// Pagination
$pagination-border-color: $light;
$pagination-color: $black;

// Hamburger
$hamburger-size: 30px;
$hamburger-color: $body-color;
$hamburger-bar-spacing: 4px;

// Shadows
$box-shadow: 0 0 100px 0 rgb(0 0 0 / 10%);

// Tooltip
$tooltip-max-width: 300px;
