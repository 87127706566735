.btn-download {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 15px 30px;

  svg {
    @include font-size(30px);
  }
}

.btn-outline-primary {
  color: $body-color;
}

.btn-link {
  padding: 0;
  border: 0;
}
