@import "~bootstrap/scss/mixins";
@import "../theme/variables";
@import "../theme/fonts";

.site-header {
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.site-header .navbar > .container {
  @include media-breakpoint-up(xl) {
    max-width: 1800px;
  }
}

.nav-btn {
  margin-top: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(xl) {
    margin: 0;
  }

  .nav-link {
    @extend .btn !optional;
    @extend .btn-primary !optional;

    padding: $btn-padding-y $btn-padding-x !important;
  }
}

#header-nav-primary {
  .navbar-nav {
    column-gap: 40px;
  }

  .nav-item {
    display: flex;
    align-items: center;
  }

  .nav-link {
    svg {
      @include font-size(20px);

      margin-right: 10px;
      height: unset !important;
      width: 1em;
    }

    &.active {
      font-weight: bold;
    }
  }
}
