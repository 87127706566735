ul.list-formatted,
ol.list-numbered {
  --list-border-width: 50px;
  --list-border-height: 2px;
  --list-border-gap: 20px;
  --list-item-margin-y: 20px;
  --list-margin-y: 20px;

  padding-left: 0;
  margin: var(--list-margin-y) 0;

  li {
    position: relative;
    list-style: none;
    font-weight: bold;
    color: var(--foreground, #{$secondary});
    padding-left: calc(var(--list-border-gap) + var(--list-border-width));
    margin: var(--list-item-margin-y) 0;

    &::before {
      position: absolute;
      display: block;
    }
  }
}

ul.list-formatted {
  li {
    &::before {
      width: var(--list-border-width);
      height: var(--list-border-height);
      background: var(--foreground, #{$secondary});
      content: " ";

      // Text height equals line height times font size. To get the middle y position, divide by two, and offset line by half of its height.
      top: calc(
        (#{$line-height-base} * 1em / 2) - (var(--list-border-height) / 2)
      );
      left: 0;
    }
  }
}

ol.list-numbered {
  li {
    counter-increment: custom-counter;

    a {
      color: var(--foreground, #{$body-color});
    }

    &::before {
      width: var(--list-border-width);
      height: $line-height-base * 1em;
      content: "0" counter(custom-counter);
      color: var(--foreground, #{$body-color});
      left: 0;
    }
  }
}
