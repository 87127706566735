@import "../theme/variables";

.order-list-results__wrapper {
  padding: 20px 30px;
  margin: 10px 0 20px;
  border-bottom: 5px solid $primary;

  .result {
    display: block;
    font-weight: bold;
    position: relative;
    padding-left: 15px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &.result-done:before {
      background-color: $green;
    }

    &.result-progress::before {
      background-color: $yellow;
    }

    &.result-error::before {
      background-color: $red;
    }
  }
}
