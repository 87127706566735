main,
.page-header__links {
  a {
    color: var(--foreground, inherit);
    text-decoration: none;
    font-weight: bold;

    &:not(.btn) {
      text-decoration: underline;
      text-decoration-color: $primary;
      text-underline-offset: var(--link-decoration-offset, 0.4em);
      text-decoration-thickness: var(--link-decoration-width, 5px);
    }

    &:has(>.detail-results__wrapper) {
      text-decoration: none;
    }
  }

  ul,
  p {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.link {
  color: var(--foreground, inherit);
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: $primary;
  text-underline-offset: var(--link-decoration-offset, 0.4em);
  text-decoration-thickness: var(--link-decoration-width, 5px);

  // remove default style
  padding: 0;
  border: 0;

  &.btn:focus {
    box-shadow: none;
  }
}

h1,
.h1,
h2,
.h2 {
  font-weight: 300;
}

h3,
.h3 {
  font-weight: 700;
}
