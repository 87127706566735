/*! purgecss start ignore */
.dropdown-toggle {
  position: relative;

  --b: 2px;

  height: $font-size-base;
  width: $font-size-base;
  aspect-ratio: 1/1;
  border: none;
  display: inline-block;
  padding: 0;
  background: none;

  &:focus,
  &:active {
    outline: none;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: var(--b);
    height: 100%;
    background: $primary;
    top: 0;
    left: calc(50% - var(--b) / 2);
    border: none;
    vertical-align: unset;
    transform-origin: center;
  }

  &::after {
    transform: rotate(90deg);
  }

  &.show {
    &::before {
      transform: rotate(90deg);
    }
  }

  .site-service & {
    &::after {
      border: none;
      border-top: 2px solid $primary;
      border-left: 2px solid $primary;
      height: 8px;
      width: 8px;
      margin-left: 10px;
      transform: rotate(225deg);
      display: inline-block;
      background: none;
    }

    &::before {
      display: none;
    }
  }

  @include media-breakpoint-up(xl) {
    &::after {
      border: none;
      border-top: 2px solid $primary;
      border-left: 2px solid $primary;
      height: 8px;
      width: 8px;
      margin-left: 10px;
      transform: rotate(225deg);
      display: inline-block;
      background: none;
    }

    &::before {
      display: none;
    }
  }
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0;
  border: 0;

  @include media-breakpoint-up(xl) {
    box-shadow: $box-shadow;
  }
}

.menu-item.dropdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .dropdown-toggle {
    flex-shrink: 0;
    margin-left: auto;
  }

  .dropdown-menu {
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: auto;
    }
  }
}

/*! purgecss end ignore */
