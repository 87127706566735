@import "src/theme/variables";

.detail-results__wrapper {
  padding: 20px 30px;
  margin: 10px 0 20px;
  border-bottom: 5px solid $primary;

  .result {
    display: block;
    font-weight: bold;
    position: relative;
    padding-left: 15px;

    .ball {
      position: absolute;
      left: 0;
      top: 8px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &.result-qualified .ball {
      background-color: $green;
    }

    &.result-not-qualified .ball {
      background-color: $yellow;
    }

    &.result-none .ball {
      background-color: $red;
    }
  }
}

.downloads-wrapper {
  a {
    &:last-of-type {
      margin-left: 15px;
    }
  }

  svg {
    position: relative;
    right: 10px;
    bottom: 3px;
  }

  .spinner-border {
    position: relative;
    right: 5px;
  }
}

// print styles
.order-print-wrapper {
  display: none;
}

.order-print {
  margin: 1cm;
  font-size: 12px;
  .overview-results {
    break-inside: avoid;
    font-size: 12px;
  }

  .print-header,
  .print-header-space {
    padding-top: 1cm;
    height: 120px;
  }

  .print-header {
    .print-header--logo {
      position: absolute;
      right: 2.5cm;
      top: 1cm;
    }
  }

  .print-footer,
  .print-footer-space {
    height: 80px;
    font-size: 12px;
  }

  .print-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  .print-header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .result {
    .ball {
      width: 10px;
      height: 10px;
      top: 3px;
    }
  }

  thead {
    display: table-header-group;
    break-inside: avoid;
  }
  tfoot {
    display: table-footer-group;
    break-inside: avoid;
  }
}
