@use "sass:color";

.splide {
  &__arrows {
    position: absolute;
    left: 3vw;
    right: 3vw;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }

  &__arrow {
    background: $primary;
    fill: $white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: background 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }

    @include media-breakpoint-up(lg) {
      width: 70px;
      height: 70px;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    &:disabled {
      pointer-events: none;
      background: $text-muted;
    }

    &:hover {
      background: color.scale($primary, $lightness: -10%);
    }

    &:active {
      @extend .shadow;
    }

    &--next {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__pagination {
    gap: 20px;
    margin-top: 60px;

    li {
      background-color: #e7e8dd;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      position: relative;
    }

    &__page {
      position: absolute;
      top: 3px;
      bottom: 3px;
      right: 3px;
      left: 3px;
      border: 0;
      border-radius: 50%;
      background: transparent;
      appearance: none;

      &.is-active {
        background-color: $secondary;
      }
    }
  }
}
