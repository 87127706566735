@use "sass:map";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Nexa Text";
  src: url("./fonts/5936887/e051a7c3-681f-4ad0-90a1-65ab6a502b41.woff2")
      format("woff2"),
    url("./fonts/5936887/873e803c-16d8-4a85-8d41-70cda767bb87.woff")
      format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Nexa Text";
  src: url("./fonts/5936879/bfd9ce2f-0800-46c4-824e-890a5a08d1c1.woff2")
      format("woff2"),
    url("./fonts/5936879/91acdf02-0214-4268-bc0b-ddb8abde4078.woff")
      format("woff");
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "Nexa Text";
  src: url("./fonts/5937120/43f3177d-3079-4bc8-93fc-c510bd93e0d3.woff2")
      format("woff2"),
    url("./fonts/5937120/3139acad-1447-41ba-9a68-0119651c3699.woff")
      format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Nexa Text";
  src: url("./fonts/5937102/52489ddb-c388-45b8-b40e-d48b231c165b.woff2")
      format("woff2"),
    url("./fonts/5937102/b36c9f3f-34e3-4aba-8045-245b186091ad.woff")
      format("woff");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Nexa;
  src: url("./fonts/5936927/56c00146-e844-4cbd-8b15-adecdb734aae.woff2")
      format("woff2"),
    url("./fonts/5936927/1fb054c5-057e-43ea-9272-f462f24e2293.woff")
      format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Nexa;
  src: url("./fonts/5937041/ee81dc59-4c2d-4c07-9563-9a407f35acb9.woff2")
      format("woff2"),
    url("./fonts/5937041/7eaa4de0-908e-4e14-a196-80bd2fe1fa91.woff")
      format("woff");
  font-style: normal;
  font-weight: 700;
}
