@import "~bootstrap/scss/mixins";

.login {
  &__title {
    @include font-size(48px);
  }

  &__logo {
    svg {
      width: auto;
      height: 60px;
    }
  }
}
