/**
* Polyfill for ml-*, mr-*, pl-* and pr-* classes for bootstrap 5.
*/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}r#{$infix}-#{$size} {
          #{$prop}-right: $length;
        }
        .#{$abbrev}l#{$infix}-#{$size} {
          #{$prop}-left: $length;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if "#{$size}"not"0" {
        .mr#{$infix}-n#{$size} {
          margin-right: -$length;
        }
        .ml#{$infix}-n#{$size} {
          margin-left: -$length;
        }
      }
    }

    // Some special margin utils
    .mr#{$infix}-auto {
      margin-right: auto;
    }
    .ml#{$infix}-auto {
      margin-left: auto;
    }
  }
}
