@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.site-sub-footer {
  .navbar {
    padding: 0;
  }

  .navbar-nav {
    gap: 15px;

    .nav-link {
      padding: 0;
      color: $white;
    }
  }
}
