@import "~bootstrap/scss/mixins";
@import "../theme/variables";
@import "../theme/fonts";

.card {
  border: none;
  height: 100%;

  &__header {
    line-height: 1;

    &-icon {
      width: 2.375em;
      height: 2.375em;
      overflow: hidden;
      background-color: $secondary;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-title {
      @include font-size(24px);
    }
  }

  &__image {
    width: 100%;
    height: 200px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: $light;
    @include font-size(70px);
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-link {
      margin-top: auto;

      a {
        font-weight: normal;

        &::after {
          content: "‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ";
        }
      }
    }
  }
}
